<!-- 代码已包含 CSS：使用 TailwindCSS , 安装 TailwindCSS 后方可看到布局样式效果 -->
<template>
  <div class="font-sans">
    <TheHeader />
    
    <main class="pt-16">
      <section class="relative min-h-[800px] pb-32 pt-[200px] overflow-hidden bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50">
        <div class="absolute inset-0">
          <div class="absolute inset-0 bg-[radial-gradient(circle_at_top_right,rgba(99,102,241,0.1),transparent_40%)]" />
          <div class="absolute inset-0 bg-[radial-gradient(circle_at_40%_60%,rgba(139,92,246,0.1),transparent_30%)]" />
          <img
            src="https://doc-oss.tduckcloud.com/doc/25edcbe7855d861060e190a004b6581e.jpg"
            alt="background pattern"
            class="absolute inset-0 w-full h-full object-cover mix-blend-overlay opacity-40"
          >
        </div>
        <div class="max-w-[1200px] mx-auto px-8 relative">
          <div class="grid grid-cols-12 gap-8">
            <div class="col-span-6 flex flex-col justify-center pr-8 animate-float" data-aos="fade-right">

              <h1 class="text-6xl font-bold mb-8 bg-gradient-to-r from-[#4C4ED3] via-[#4C4ED3] to-[#4C4ED3] bg-clip-text text-transparent leading-tight animate-gradient-x ">
                填鸭表单<br/>为每个先进组织提效
              </h1>
              <p class="text-xl text-gray-600 mb-8 leading-relaxed">
                新一代智能表单系统，重新定义数据收集方式。
                支持私有化部署<br/>分钟级构建企业业务流转平台，让数据收集更高效。
              </p>
              <div class="space-y-6 mb-12">
                <div class="flex items-center space-x-3 min-h-[24px]">
                  <i class="fas fa-check text-purple-400" />
                  <span class=" overflow-hidden whitespace-nowrap pr-1 text-violet-600 min-w-[300px]">
                    {{ currentTypeText }}
                  </span>
                </div>
              </div>
              <div class="flex items-center space-x-6">
                <button 
                  @click="modalStore.openDemoModal()"
                  class="px-8 py-4 bg-gradient-to-r from-[#4C4ED3] to-[#4C4ED3] text-white hover:from-indigo-600 hover:to-purple-600 !rounded-button whitespace-nowrap shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-xl group relative overflow-hidden"
                >
                  <div class="absolute inset-0 bg-white/20 transform -skew-x-12 translate-x-full group-hover:translate-x-0 transition-transform duration-700" />
                  <i class="fas fa-rocket mr-2 group-hover:rotate-45 transition-transform" />
                  开始体验
                </button>
                <button class="px-8 py-4 bg-transparent text-[#4C4ED3] border-2 border-[#4C4ED3] hover:bg-purple-50/20 !rounded-button whitespace-nowrap shadow-lg backdrop-blur-sm transform transition-all duration-300 hover:scale-105 hover:shadow-xl" @click="toVideo()">
                  <i class="fas fa-play-circle mr-2" />
                  视频介绍
                </button>
              </div>
            </div>
            <div class="col-span-6 relative" data-aos="fade-up">
              <div class="absolute -top-20 -right-20 w-72 h-72 bg-purple-600/30 rounded-full blur-3xl" />
              <div class="absolute -bottom-8 -left-8 w-72 h-72 bg-blue-600/20 rounded-full blur-3xl" />
              <div class="absolute -bottom-8 -left-8 w-72 h-72 bg-blue-600/20 rounded-full blur-3xl" />
              <div class="relative">
                <div class="relative z-10 overflow-hidden rounded-xl scale-[1.5]">
                  <div class="flex -space-x-20 items-center justify-center fan-container">
                    <img 
                      src="https://doc-oss.tduckcloud.com/doc/biaodan.png" 
                      alt="填鸭表单界面" 
                      class="w-1/3 rounded-xl transform transition-all duration-500 hover:scale-105 fan-item"
                    >
                    <img 
                      src="https://doc-oss.tduckcloud.com/doc/ceping.png" 
                      alt="填鸭表单界面" 
                      class="w-1/3 rounded-xl transform transition-all duration-500 hover:scale-105 fan-item"
                    >
                    <img 
                      src="https://doc-oss.tduckcloud.com/doc/liucheng.png" 
                      alt="填鸭表单界面" 
                      class="w-1/3 rounded-xl transform transition-all duration-500 hover:scale-105 fan-item"
                    >
                  </div>
                </div>
              </div>
            </div>
          <div class="col-span-12 flex justify-center mt-10">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <a v-for="(news, index) in newsItems" :key="index" :href="news.link" target="_blank" class="group p-8 bg-white rounded-xl border border-gray-100 transition-all duration-300 hover:shadow-lg hover:border-[#4C4ED3]/20">
                <span class="inline-block px-3 py-1 text-sm text-[#4C4ED3] bg-[#4C4ED3]/5 rounded-full mb-6">{{ news.tag }}</span>
                <h3 class="text-2xl font-semibold text-gray-900 mb-4 group-hover:text-[#4C4ED3] line-clamp-2 transition-colors">{{ news.title }}</h3>
                <p class="text-gray-500 mb-6 line-clamp-3">{{ news.description }}</p>
                <div class="flex items-center text-[#4C4ED3] opacity-0 group-hover:opacity-100 transition-opacity">
                  <span class="font-medium text-sm">阅读更多</span>
                  <i class="fas fa-arrow-right ml-2 text-sm transform group-hover:translate-x-1 transition-transform" />
                </div>
              </a>
            </div>
            </div>
            <div class="col-span-12 flex justify-center mt-24">
              <div class="flex items-center space-x-8 px-8 py-4 bg-white/5 backdrop-blur-sm rounded-full border border-white/20">
                <span class="text-gray-400">值得信赖的企业级方案</span>
                <div class="h-4 w-px bg-gray-400" />
                <div class="flex items-center space-x-8">
                  <span class="flex items-center text-gray-400">
                    <i class="fas fa-check-circle text-green-400 mr-2" />
                    支持内网部署
                  </span>
                  <span class="flex items-center text-gray-400">
                    <i class="fas fa-check-circle text-green-400 mr-2" />
                    支持信创环境
                  </span>
                  <span class="flex items-center text-gray-400">
                    <i class="fas fa-check-circle text-green-400 mr-2" />
                    7×24h 技术支持
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="py-20 bg-gradient-to-br from-purple-50 to-white">
        <div class="max-w-7xl mx-auto px-8">
          <div class="text-center mb-16 animate-float">
            <h2 class="text-4xl font-bold text-gray-900 mb-6">
              一次部署，永久使用
            </h2>
            <p class="text-xl text-gray-600">
              系统功能强大完善，部署简单方便，覆盖企业各类数据收集场景
            </p>
          </div>
          <div class="grid grid-cols-4 gap-8">
            <div class="p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow flex flex-col items-center text-center space-y-3">
              <div class="w-12 h-12 rounded-full bg-violet-100 flex items-center justify-center">
                <i class="fas fa-server text-violet-600 text-xl" />
              </div>
              <h3 class="font-semibold text-gray-900">
                私有化部署
              </h3>
              <p class="text-sm text-gray-600">
                支持本地部署和私有云部署
              </p>
            </div>
            <div class="p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow flex flex-col items-center text-center space-y-3">
              <div class="w-12 h-12 rounded-full bg-indigo-100 flex items-center justify-center">
                <i class="fas fa-code text-indigo-600 text-xl" />
              </div>
              <h3 class="font-semibold text-gray-900">
                100% 源码
              </h3>
              <p class="text-sm text-gray-600">
                完整源代码，自由定制功能
              </p>
            </div>
            <div class="p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow flex flex-col items-center text-center space-y-3">
              <div class="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center">
                <i class="fas fa-puzzle-piece text-blue-600 text-xl" />
              </div>
              <h3 class="font-semibold text-gray-900">
                40+ 组件
              </h3>
              <p class="text-sm text-gray-600">
                丰富的组件库，轻松构建表单
              </p>
            </div>
            <div class="p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow flex flex-col items-center text-center space-y-3">
              <div class="w-12 h-12 rounded-full bg-green-100 flex items-center justify-center">
                <i class="fas fa-database text-green-600 text-xl" />
              </div>
              <h3 class="font-semibold text-gray-900">
                百万级收集
              </h3>
              <p class="text-sm text-gray-600">
                高性能架构，海量数据处理
              </p>
            </div>
            <div class="p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow flex flex-col items-center text-center space-y-3">
              <div class="w-12 h-12 rounded-full bg-orange-100 flex items-center justify-center">
                <i class="fas fa-headset text-orange-600 text-xl" />
              </div>
              <h3 class="font-semibold text-gray-900">
                7*24h 服务
              </h3>
              <p class="text-sm text-gray-600">
                专业技术支持，快速响应
              </p>
            </div>
            <div class="p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow flex flex-col items-center text-center space-y-3">
              <div class="w-12 h-12 rounded-full bg-pink-100 flex items-center justify-center">
                <i class="fas fa-language text-pink-600 text-xl" />
              </div>
              <h3 class="font-semibold text-gray-900">
                多语言支持
              </h3>
              <p class="text-sm text-gray-600">
                支持多国语言，国际化部署
              </p>
            </div>
            <div class="p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow flex flex-col items-center text-center space-y-3">
              <div class="w-12 h-12 rounded-full bg-teal-100 flex items-center justify-center">
                <i class="fas fa-code-branch text-teal-600 text-xl" />
              </div>
              <h3 class="font-semibold text-gray-900">
                全自研产品
              </h3>
              <p class="text-sm text-gray-600">
                自主知识产权，持续创新
              </p>
            </div>
            <div class="p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow flex flex-col items-center text-center space-y-3">
              <div class="w-12 h-12 rounded-full bg-cyan-100 flex items-center justify-center">
                <i class="fas fa-infinity text-cyan-600 text-xl" />
              </div>
              <h3 class="font-semibold text-gray-900">
                永久免费使用
              </h3>
              <p class="text-sm text-gray-600">
                社区版免费开源，长期维护
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="business-scenarios" class="py-32 bg-gray-50">
        <div id="scenario-nav" class="fixed bottom-12 left-0 right-0 transform transition-all duration-300 translate-y-full opacity-0 z-40">
          <div class="max-w-7xl mx-auto px-8">
            <div class="mx-auto max-w-5xl bg-[#4C4ED3]/10 backdrop-blur-sm rounded-full shadow-lg">
              <div class="grid grid-cols-7 divide-x divide-gray-100">
                <a href="#data-collection" class="scenario-nav-link flex items-center justify-center text-gray-600 hover:text-[#4C4ED3] py-4 text-sm font-medium transition-colors duration-200">
                  <i class="fas fa-database mr-2" />数据采集
                </a>
                <a href="#workflow-approval" class="scenario-nav-link flex items-center justify-center text-gray-600 hover:text-[#4C4ED3] py-3 text-sm font-medium transition-colors duration-200">
                  <i class="fas fa-tasks mr-2" />流程审批
                </a>
                <a href="#exam-evaluation" class="scenario-nav-link flex items-center justify-center text-gray-600 hover:text-[#4C4ED3] py-3 text-sm font-medium transition-colors duration-200">
                  <i class="fas fa-pencil-alt mr-2" />考试测评
                </a>
                <a href="#order-payment" class="scenario-nav-link flex items-center justify-center text-gray-600 hover:text-[#4C4ED3] py-3 text-sm font-medium transition-colors duration-200">
                  <i class="fas fa-credit-card mr-2" />订单收款
                </a>
                <a href="#event-booking" class="scenario-nav-link flex items-center justify-center text-gray-600 hover:text-[#4C4ED3] py-3 text-sm font-medium transition-colors duration-200">
                  <i class="fas fa-calendar-alt mr-2" />活动预约
                </a>
                <a href="#vote-verify" class="scenario-nav-link flex items-center justify-center text-gray-600 hover:text-[#4C4ED3] py-3 text-sm font-medium transition-colors duration-200">
                  <i class="fas fa-vote-yea mr-2" />投票核销
                </a>
                <a href="#template-print" class="scenario-nav-link flex items-center justify-center text-gray-600 hover:text-[#4C4ED3] py-3 text-sm font-medium transition-colors duration-200">
                  <i class="fas fa-print mr-2" />模板打印
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="max-w-[80%] mx-auto px-8 function">
          <div class="text-center mb-24">
            <h2 class="text-5xl font-bold text-gray-900 mb-6">
              一个表单，多种业务场景
            </h2>
            <p class="text-xl text-gray-600">
              40+ 内置组件，巧妙搭配使用，轻松满足 90% 的日常业务需求；使用填鸭表单让效率倍增
            </p>
            <div class="flex flex-wrap justify-center items-center gap-3 mt-8">
              <span class="px-4 py-2 bg-red-100 text-red-700 rounded-full text-sm font-medium">信创环境</span>
              <span class="px-4 py-2 bg-blue-100 text-blue-700 rounded-full text-sm font-medium">快速部署</span>
              <span class="px-4 py-2 bg-[#4C4ED3]/10 text-[#4C4ED3] rounded-full text-sm font-medium">无代码开发</span>
              <span class="px-4 py-2 bg-green-100 text-green-700 rounded-full text-sm font-medium">低成本</span>
              <span class="px-4 py-2 bg-orange-100 text-orange-700 rounded-full text-sm font-medium">高扩展</span>
              <span class="px-4 py-2 bg-teal-100 text-teal-700 rounded-full text-sm font-medium">数据安全</span>
            </div>
          </div>
          <div class="grid grid-cols-1 gap-12 scene">
            <!-- 数据收集 -->
            <div id="data-collection" class="bg-purple-50/80 rounded-xl p-16 transition-all duration-300 hover:shadow-lg flex justify-between items-center">
                <!-- 左侧图片部分，增加宽度 -->
                <div class="flex w-[85%] scale-90">
                  <!-- 左侧大图 -->
                  <div class="w-1/2 h-full flex-shrink-0 relative z-10">
                    <img 
                      src="https://doc-oss.tduckcloud.com/doc/img1@1x.png" 
                      alt="数据采集" 
                      class="w-full h-full object-cover animate-float-1"
                    >
                  </div>

                  <!-- 右侧两张图片垂直排列 -->
                  <div class="flex flex-col gap-8 w-[85%] h-full -translate-x-32">
                    <div class="flex-1">
                      <img 
                        src="https://doc-oss.tduckcloud.com/doc/img2@1x.png" 
                        alt="数据采集" 
                        class="w-full h-full object-cover animate-float-2"
                      >
                    </div>
                    <div class="flex-1">
                      <img 
                        src="https://doc-oss.tduckcloud.com/doc/img3@1x.png" 
                        alt="数据采集" 
                        class="w-full h-full object-cover animate-float-3"
                      >
                    </div>
                  </div>
                </div>

                <!-- 右侧文字部分，减少宽度并向左移动 -->
                <div class="w-[65%] -ml-8">
                  <div class="flex items-center mb-4">
                    <h3 class="text-4xl font-bold text-gray-900">简单拖拽<br/>数据收集轻松搞定</h3>
                  </div>
                  <p class="text-gray-600">自定义组件快速创建调查问卷、反馈表单，支持多种题型和逻辑设置<br/>实现精准数据收集</p>
                  <div class="mt-4 flex flex-wrap gap-3">
                    <span class="text-xs px-2 py-1 bg-blue-50 text-blue-600 rounded">问卷调查</span>
                    <span class="text-xs px-2 py-1 bg-blue-50 text-blue-600 rounded">满意度调研</span>
                    <span class="text-xs px-2 py-1 bg-blue-50 text-blue-600 rounded">意见反馈</span>
                    <span class="text-xs px-2 py-1 bg-blue-50 text-blue-600 rounded">业务信息收集</span>
                  </div>
                  <div class="mt-4 space-y-2">
                    <a href="https://x.tduckcloud.com/s/4XLvN7fI" class="block text-sm text-blue-600 hover:text-blue-700" target="_blank">
                      <i class="fas fa-arrow-right text-xs mr-2" />
                      客户满意度调查表 Demo
                    </a>
                    <a href="https://www.tduckcloud.com/doc/help/wqzd2OZw" class="block text-sm text-blue-600 hover:text-blue-700" target="_blank">
                      <i class="fas fa-arrow-right text-xs mr-2" />
                      如何创建一个表单？
                    </a>
                  </div>
                </div>
              </div>

              <!-- 流程审批 -->
              <div id="workflow-approval" class="scenario-card workflow">
                <!-- 文字部分移到左边 -->
                <div class="content-wrapper">
                  <div class="title-wrapper">
                    <h3 class="title">这真的有点酷<br/>表单也能做流程审批</h3>
                  </div>
                  <p class="description">自定义审批流程，支持多级审批，实时跟踪审批状态，提高工作效率</p>
                  <div class="tags-wrapper">
                    <span class="tag">请假申请</span>
                    <span class="tag">报销审批</span>
                    <span class="tag">合同审批</span>
                  </div>
                  <div class="links-wrapper">
                    <a href="https://www.tduckcloud.com/doc/help/pas5utbP" class="link-item" target="_blank">
                      <i class="fas fa-arrow-right icon"></i>
                      如何创建流程审批？ Demo
                    </a>
                  </div>
                </div>
                <!-- 图片部分移到右边 -->
                <div class="image-container">
                  <div class="main-image-wrapper">
                    <img 
                      src="https://doc-oss.tduckcloud.com/doc/lc1.png" 
                      alt="流程审批" 
                      class="main-image"
                    >
                  </div>
                  <div class="sub-images-wrapper">
                    <div class="sub-image">
                      <img 
                        src="https://doc-oss.tduckcloud.com/doc/lc2.png" 
                        alt="流程审批" 
                        class="sub-image-item"
                      >
                    </div>
                    <div class="sub-image">
                      <img 
                        src="https://doc-oss.tduckcloud.com/doc/lc3.png" 
                        alt="流程审批" 
                        class="sub-image-item"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!-- 考试测评 -->
              <div id="exam-evaluation" class="scenario-card exam-card">
                <div class="image-container">
                  <div class="main-image-wrapper">
                    <img 
                      src="https://doc-oss.tduckcloud.com/doc/cp1.png" 
                      alt="考试测评" 
                      class="main-image"
                    >
                  </div>
                  <div class="sub-images-wrapper">
                    <div class="sub-image">
                      <img 
                        src="https://doc-oss.tduckcloud.com/doc/cp2.png" 
                        alt="考试测评" 
                        class="sub-image-item"
                      >
                    </div>
                    <div class="sub-image sub-image:last-child">
                      <img 
                        src="https://doc-oss.tduckcloud.com/doc/cp3.png" 
                        alt="考试测评" 
                        class="sub-image-item"
                      >
                    </div>
                    
                  </div>
                </div>
                <div class="content-wrapper">
                  <div class="title-wrapper">
                    <h3 class="title">在线考试/人工评分<br/>360°测评/样样精通</h3>
                  </div>
                  <p class="description">支持多种评分题型，证书生成，数据分析<br/>适用于培训考核场景</p>
                  <div class="tags-wrapper">
                    <span class="tag exam-tag">在线考试</span>
                    <span class="tag exam-tag">知识测评</span>
                    <span class="tag exam-tag">技能评估</span>
                  </div>
                  <div class="links-wrapper">
                    <a href="https://x.tduckcloud.com/s/u7pXIw0x" class="link-item exam-link" target="_blank">
                      <i class="fas fa-arrow-right icon"></i>
                      英语四级测试 Demo
                    </a>
                    <a href="https://x.tduckcloud.com/s/V38xhWcf" class="link-item exam-link" target="_blank">
                      <i class="fas fa-arrow-right icon"></i>
                      霍兰德职业兴趣测试 Demo
                    </a>
                      <a href="https://www.tduckcloud.com/doc/help/ZNpqqceJ" class="link-item exam-link" target="_blank">
                      <i class="fas fa-arrow-right icon"></i>
                      如何创建考试测评？
                    </a>
                  </div>
                </div>
              </div>
              <!-- 订单收款 -->
              <div id="order-payment" class="scenario-card order-card">
                <div class="content-wrapper">
                  <div class="title-wrapper">
                    <h3 class="title">实在是太妙了<br/>用表单快速搭建[商品收款]</h3> 
                  </div>
                  <p class="description">支持多种支付方式，订单管理，对账统计，让收款更简单高效</p>
                  <div class="tags-wrapper">
                    <span class="tag order-tag">在线支付</span>
                    <span class="tag order-tag">订单管理</span>
                    <span class="tag order-tag">财务统计</span> 
                  </div>
                  <div class="links-wrapper">
                    <a href="https://x.tduckcloud.com/s/7zhrzb3u" class="link-item order-link" target="_blank">
                      <i class="fas fa-arrow-right icon"></i>
                      牛肉干购买 Demo
                    </a> 
                    <a href="https://x.tduckcloud.com/s/KFQ37EOi" class="link-item order-link" target="_blank">
                      <i class="fas fa-arrow-right icon"></i>
                      文创商品购买链接 Demo
                    </a>
                  </div>
                </div>
                <div class="image-container">
                  <div class="main-image-wrapper">
                    <img 
                      src="https://doc-oss.tduckcloud.com/doc/sk1.png" 
                      alt="订单收款" 
                      class="main-image"
                    >
                  </div>
                  <div class="sub-images-wrapper">
                    <div class="sub-image sub-image:first-child"> 
                      <img 
                        src="https://doc-oss.tduckcloud.com/doc/sk2.png" 
                        alt="订单收款" 
                        class="sub-image-item"
                      >
                    </div> 
                    <div class="sub-image sub-image:last-child">
                      <img 
                        src="https://doc-oss.tduckcloud.com/doc/sk3.png" 
                        alt="订单收款" 
                        class="sub-image-item"
                      > 
                    </div>
                  </div>
                </div>
              </div>
              <!-- 活动预约 -->
              <div id="event-booking" class="scenario-card event-card">
                <div class="image-container">
                    <div class="main-image-wrapper">
                    <img 
                      src="https://doc-oss.tduckcloud.com/doc/yy1.png" 
                      alt="活动预约" 
                      class="main-image"
                    >
                    </div>
                    <div class="sub-images-wrapper">
                        <div class="sub-image">
                            <img 
                                src="https://doc-oss.tduckcloud.com/doc/yy2.png" 
                                alt="活动预约" 
                                class="sub-image-item"
                            >
                        </div>
                    </div>
                </div> 
                <div class="content-wrapper">
                    <div class="title-wrapper">
                        <h3 class="title">活动预约/动态通知<br/>让活动预约更简单高效</h3>
                    </div>
                    <p class="description">支持多种活动类型，预约管理，数据统计，让活动预约更简单高效</p>
                    <div class="tags-wrapper">
                        <span class="tag event-tag">活动预约</span>
                        <span class="tag event-tag">预约管理</span>
                        <span class="tag event-tag">数据统计</span>
                    </div>
                    <div class="links-wrapper"> 
                        <a href="https://x.tduckcloud.com/s/1cZ5EbZE" class="link-item event-link" target="_blank">
                            <i class="fas fa-arrow-right icon"></i>
                            景区入园预约系统 Demo
                        </a> 
                        <a href="https://www.tduckcloud.com/doc/help/VwQHxZlo" class="link-item event-link" target="_blank">
                            <i class="fas fa-arrow-right icon"></i>
                            活动如何群发通知？
                        </a> 
                    </div>
                </div> 
              </div>
              <!-- 投票核销 -->
              <div id="vote-verify" class="scenario-card vote-card">
                <div class="content-wrapper">
                    <div class="title-wrapper">
                        <h3 class="title">快速搭建投票/核销<br/>让投票核销更简单高效</h3>
                    </div>
                    <p class="description">支持多种投票方式，防刷票机制，实时数据统计，让投票核销更简单高效</p>
                    <div class="tags-wrapper">
                        <span class="tag vote-tag">投票核销</span>
                        <span class="tag vote-tag">防刷票</span>
                        <span class="tag vote-tag">数据统计</span>
                    </div>
                    <div class="links-wrapper">
                        <a href="https://x.tduckcloud.com/s/o34eZb3c" class="link-item vote-link" target="_blank">
                            <i class="fas fa-arrow-right icon"></i>
                            投票核销系统 Demo
                        </a>
                    </div>
                </div>  
                <div class="image-container">
                    <div class="main-image-wrapper">
                        <img 
                            src="https://doc-oss.tduckcloud.com/doc/tp1.png" 
                            alt="投票核销" 
                            class="main-image"
                        >
                    </div>
                    <div class="sub-images-wrapper">
                        <div class="sub-image sub-image:first-child">
                            <img   
                                src="https://doc-oss.tduckcloud.com/doc/tp2.png" 
                                alt="投票核销" 
                                class="sub-image-item"
                            >
                        </div>
                        <div class="sub-image sub-image:last-child">
                            <img   
                                src="https://doc-oss.tduckcloud.com/doc/tp3.png" 
                                alt="投票核销" 
                                class="sub-image-item"
                            >
                        </div>
                    </div>  
                </div>

              </div>
              
              <!-- 模板打印 -->
              <div id="template-print" class="scenario-card template-card">
                <div class="image-container">
                    <div class="main-image-wrapper main-image-wrapper:first-child">
                        <img 
                          src="https://doc-oss.tduckcloud.com/doc/dy1.png" 
                          alt="模板打印" 
                          class="main-image"
                        >
                    </div>
                    <div class="sub-image template-sub-image1">
                        <img 
                            src="https://doc-oss.tduckcloud.com/doc/dy2.png" 
                            alt="模板打印" 
                            class="sub-image-item"
                        >
                    </div>
                      <div class="sub-image template-sub-image2">
                        <img 
                            src="https://doc-oss.tduckcloud.com/doc/dy3.png" 
                            alt="模板打印" 
                            class="sub-image-item"
                        >
                      </div>
                </div> 
                <div class="content-wrapper">
                    <div class="title-wrapper">
                        <h3 class="title">自定义模板打印<br/>数据跟着你的风格走</h3>
                    </div>
                    <p class="description">支持多种模板打印，模板管理，数据统计，让模板打印更简单高效</p>
                    <div class="tags-wrapper">
                        <span class="tag template-tag">模板打印</span>
                        <span class="tag template-tag">模板管理</span>
                        <span class="tag template-tag">数据统计</span>
                    </div>
                    <div class="links-wrapper">
                        <a href="https://x.tduckcloud.com/s/igOmOwyN" class="link-item template-link" target="_blank">
                            <i class="fas fa-arrow-right icon"></i>
                            荣誉证书下载 Demo
                        </a>
                    </div>
                </div>  
              </div>  
          </div>
        </div>
      </section>
      <section class="py-24 bg-gradient-to-br from-gray-50 to-white state">
        <div class="max-w-7xl mx-auto px-8">
          <div class="text-center mb-20">
            <h2 class="text-5xl font-bold text-gray-900 mb-6">
              我们的动态
            </h2>
            <p class="text-xl text-gray-600">
              了解最新产品更新与行业资讯
            </p>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <a v-for="(news, index) in newsItems" :key="index" href="#" class="group p-8 bg-white rounded-xl border border-gray-100 transition-all duration-300 hover:shadow-lg hover:border-[#4C4ED3]/20">
              <span class="inline-block px-3 py-1 text-sm text-[#4C4ED3] bg-[#4C4ED3]/5 rounded-full mb-6">{{ news.tag }}</span>
              <h3 class="text-2xl font-semibold text-gray-900 mb-4 group-hover:text-[#4C4ED3] line-clamp-2 transition-colors">{{ news.title }}</h3>
              <p class="text-gray-500 mb-6 line-clamp-3">{{ news.description }}</p>
              <div class="flex items-center text-[#4C4ED3] opacity-0 group-hover:opacity-100 transition-opacity">
                <span class="font-medium text-sm">阅读更多</span>
                <i class="fas fa-arrow-right ml-2 text-sm transform group-hover:translate-x-1 transition-transform" />
              </div>
            </a>
          </div>
        </div>
      </section>
      <section class="py-32 bg-gradient-to-br from-violet-500 to-violet-500/80 relative overflow-hidden">
        <div class="absolute inset-0">
          <div class="absolute top-0 left-0 w-96 h-96 bg-white/5 rounded-full blur-3xl" />
          <div class="absolute bottom-0 right-0 w-96 h-96 bg-white/5 rounded-full blur-3xl" />
        </div>
        <div class="max-w-7xl mx-auto px-8 relative">
          <div class="text-center py-16">
            <h2 class="text-4xl font-bold text-white mb-6">
              立即开始体验数据收集的超能力
            </h2>
            <p class="text-xl text-white/80 mb-8 max-w-2xl mx-auto">
              加入超过 15,000+ 用户的选择，使用Tduck填鸭表单，让数据收集更简单
            </p>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-4xl mx-auto mb-12">
              <div class="bg-white/10 backdrop-blur-sm rounded-lg p-6 text-white">
                <i class="fas fa-rocket text-3xl mb-4" />
                <h3 class="text-xl font-semibold mb-2">
                  快速部署
                </h3>
                <p class="text-white/80">
                  最快 5 分钟完成安装配置
                </p>
              </div>
              <div class="bg-white/10 backdrop-blur-sm rounded-lg p-6 text-white">
                <i class="fas fa-shield-alt text-3xl mb-4" />
                <h3 class="text-xl font-semibold mb-2">
                  支持内网部署
                </h3>
                <p class="text-white/80">
                  内网部署/数据私有化存储加密
                </p>
              </div>
              <div class="bg-white/10 backdrop-blur-sm rounded-lg p-6 text-white">
                <i class="fas fa-headset text-3xl mb-4" />
                <h3 class="text-xl font-semibold mb-2">
                  专业支持
                </h3>
                <p class="text-white/80">
                  7*24h 技术服务
                </p>
              </div>
            </div>
            <div class="flex items-center justify-center space-x-6">
              <button class="group px-8 py-4 bg-white text-violet-600 hover:bg-gray-100 !rounded-button whitespace-nowrap shadow-lg text-lg font-medium transition-all duration-300 hover:scale-105 " @click="modalStore.showDemoModal = true">
                <i class="fas fa-play-circle mr-2 group-hover:animate-pulse" />
                免费体验
              </button>
              <button class="group px-8 py-4 bg-transparent text-white border-2 border-white hover:bg-white/10 !rounded-button whitespace-nowrap shadow-lg text-lg font-medium transition-all duration-300 hover:scale-105" @click="clickConsult" >
                <i class="fas fa-comments mr-2 group-hover:animate-bounce" />
                在线咨询
              </button>
            </div>
          </div>
        </div>
      </section>
      <section class="py-24">
        <div class="max-w-7xl mx-auto px-8">
          <div class="text-center mb-20">
            <h2 class="text-5xl font-bold text-gray-900 mb-6">
              先进组织的共同选择
            </h2>
            <p class="text-xl text-gray-600">
              使用 "Tduck填鸭表单" 开启数据收集的超能力。
            </p>
          </div>
          <div class="grid grid-cols-6 gap-2">
            <div
              v-for="(logo, index) in [
                'https://minio.tduckcloud.com/landing/icons/zhongkeyuan.png',
                'https://minio.tduckcloud.com/landing/icons/tengxunyinyu.png',
                'https://minio.tduckcloud.com/landing/icons/xianjiaoda.png',
                'https://minio.tduckcloud.com/landing/icons/wuhandaxue.png',
                'https://minio.tduckcloud.com/landing/icons/qinghua.png',
                'https://minio.tduckcloud.com/landing/icons/guangzhoudaxue.png',
                'https://minio.tduckcloud.com/landing/icons/hdsfdx.png',
                'https://minio.tduckcloud.com/landing/icons/bjjd.png',
                'https://minio.tduckcloud.com/landing/icons/scdx.png',
                'https://minio.tduckcloud.com/landing/icons/bosch.png',
                'https://minio.tduckcloud.com/landing/icons/foxconn.png',
                'https://minio.tduckcloud.com/landing/icons/jmj.png',
                'https://minio.tduckcloud.com/landing/icons/shiyou.png',
                'https://minio.tduckcloud.com/landing/icons/yidong.png',
                'https://minio.tduckcloud.com/landing/icons/liantong.png',
                'https://minio.tduckcloud.com/landing/icons/dianxin.png',
                'https://minio.tduckcloud.com/landing/icons/qiaqia.png',
                'https://minio.tduckcloud.com/landing/icons/gjyl.png',
                'https://minio.tduckcloud.com/landing/icons/dji.png',
                'https://minio.tduckcloud.com/landing/icons/geely.png',
                'https://minio.tduckcloud.com/landing/icons/lingxi.png',
                'https://minio.tduckcloud.com/landing/icons/Hisense1.png',
                'https://minio.tduckcloud.com/landing/icons/xinshidai.png',
                'https://minio.tduckcloud.com/landing/icons/sfc.png',
                'https://minio.tduckcloud.com/landing/icons/itg.png',
                'https://minio.tduckcloud.com/landing/icons/haier.png',
                'https://minio.tduckcloud.com/landing/icons/991.png',
                'https://minio.tduckcloud.com/landing/icons/sichuanjichang.png',
                'https://minio.tduckcloud.com/landing/icons/zgrs@1x.png',
                'https://minio.tduckcloud.com/landing/icons/cryl.png',
                'https://minio.tduckcloud.com/landing/icons/yfm.png',
                'https://minio.tduckcloud.com/landing/icons/zhjt.png',
                'https://minio.tduckcloud.com/landing/icons/zgbq.png',
                'https://minio.tduckcloud.com/landing/icons/saj.png',
                'https://minio.tduckcloud.com/landing/icons/sdgs.png',
                'https://minio.tduckcloud.com/landing/icons/hdrm.png',
                'https://minio.tduckcloud.com/landing/icons/gjdszj.png',
                'https://minio.tduckcloud.com/landing/icons/tyszf.png',
                'https://minio.tduckcloud.com/landing/icons/xmszf.png',
                'https://minio.tduckcloud.com/landing/icons/Nikon.png',
                'https://minio.tduckcloud.com/landing/icons/dzqc.png',
                'https://minio.tduckcloud.com/landing/icons/Porsche.png'
              ]"
              :key="index"
              class="h-16 rounded-lg flex items-center justify-center p-2 hover:shadow-sm transition-shadow"
            >
              <img :src="logo" alt="company logo" class="h-12 object-contain">
            </div>
          </div>
        </div>
      </section>
    </main>
    <TheFooter />
    
    <!-- 添加 DemoSelectModal 组件 -->
    <DemoSelectModal />
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { Swiper } from 'swiper'
import { Pagination, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import TheHeader from '../components/home/TheHeader.vue'
import DemoSelectModal from './DemoSelectModal.vue'
import { useModalStore } from '@/stores/modal'
import TheFooter from '../components/home/TheFooter.vue'

useHead({
  title: '开源问卷表单系统-TduckSurvey',
  meta: [
    { name: 'description', content: '开源问卷表单系统-TduckSurvey' }
  ],
  link: [
    { rel: 'icon', href: '/x.ico', sizes: 'any' },
    { rel: 'stylesheet', href: '//at.alicdn.com/t/c/font_4348154_m5w0r2ywl.css' }
  ]
})
useSeoMeta({
  title: '开源问卷表单系统-TduckSurvey',
  ogTitle: '开源问卷表单系统-TduckSurvey',
  keywords: '私有化问卷系统,表单设计工具,数据采集平台,私有化部署表单系统,开源问卷源码,企业问卷管理,流程管理系统,调研工具,数据分析平台,考试管理系统,工单管理,开源表单工具,免费表单生成器',
  description: '填鸭表单是一款私有化部署的开源问卷调查系统，基于Springboot+Vue，提供高效的数据收集与管理解决方案。适用于企业私有化部署，支持流程管理，问卷分析，数据采集与发布，提供灵活的系统集成方案。',
  ogDescription: '填鸭表单是一个基于Springboot+Vue的开源问卷调查系统，支持多种数据采集渠道，适用于企业级私有化部署，提供便捷的问卷创建、发布、管理和分析服务，是企业信息采集和流程管理的好帮手。'
})


const swiperRef = ref(null)
const navItems = [
  {
    label: '文档中心',
    children: [
      {
        title: '社区版',
        items: [
          { label: '快速开始', description: '5分钟快速搭建', icon: 'fas fa-rocket' },
          { label: '使用文档', description: '详细的使用说明', icon: 'fas fa-book' },
          { label: '开发文档', description: '二次开发指南', icon: 'fas fa-code' }
        ]
      },
      {
        title: 'TDuckPro',
        items: [
          { label: '部署教程', description: '专业版安装部署', icon: 'fas fa-server' },
          { label: '功能介绍', description: '专业版特色功能', icon: 'fas fa-star' },
          { label: 'API文档', description: 'RESTful接口文档', icon: 'fas fa-plug' }
        ]
      },
      {
        title: 'TDuckX',
        items: [
          { label: '商业授权', description: '商业版授权说明', icon: 'fas fa-shield-alt' },
          { label: '私有部署', description: '商业版部署方案', icon: 'fas fa-building' },
          { label: '定制服务', description: '个性化定制方案', icon: 'fas fa-tools' }
        ]
      }
    ]
  },
  {
    label: '产品列表',
    children: [
      {
        title: '开源产品',
        items: [
          { label: 'TDUCK社区版', description: '免费开源的表单系统', icon: 'fas fa-dove' },
          { label: 'TReport', description: '开源报表设计与分析', icon: 'fas fa-chart-pie' }
        ]
      },
      {
        title: '商业产品',
        items: [
          { label: 'TDuckX', description: '企业级全功能版本', icon: 'fas fa-crown' },
          { label: 'TDuckPro', description: '适用于企业的商业版', icon: 'fas fa-rocket' },
          { label: 'Nium二维码', description: '智能二维码管理平台', icon: 'fas fa-qrcode' }
        ]
      }
    ]
  },
  {
    label: '用户案例'
  },
  {
    label: '价格'
  },
  {
    label: '开源地址'
  }
]

const features = [
  { icon: 'fas fa-server', text: '私有化部署' },
  { icon: 'fas fa-code', text: '100%源码交付' },
  { icon: 'fas fa-headset', text: '技术支持' },
  { icon: 'fas fa-clock', text: '7*24h' },
  { icon: 'fas fa-sync', text: '快速迭代' },
  { icon: 'fas fa-tachometer-alt', text: '高并发能力' },
  { icon: 'fas fa-database', text: '百万级数据收集能力' }
]
const newsItems = [
  {
    image: 'https://ai-public.mastergo.com/ai/img_res/77731ab457f064572784121442bbadee.jpg',
    tag: '功能更新',
    title: 'TDuckX全新2.4版本发布！',
    description: '全新一代表单收集系统，为您的数据收集提供更强大的支持，繁琐业务也能敏捷处理...',
    link: 'https://www.bilibili.com/video/BV1vuXMYWECk/?vd_source=76238734ab81e716b609416460cc6a3a'
  },
  {
    image: 'https://ai-public.mastergo.com/ai/img_res/29d93819b925bb4f39a140848187e279.jpg',
    tag: '行业合作',
    title: '宝塔面板×填鸭表单，一键部署',
    description: '一键部署表单应用，宝塔联合定制面板，为您提供更便捷的服务器运维管理面板服务',
    link: '/bt'
  },
  {
    image: 'https://ai-public.mastergo.com/ai/img_res/45f20a075840b4184c21a3d1794fd64e.jpg',
    tag: '功能对比',
    title: '进一步了解，选择您适合的方案',
    description: '填鸭表单提供多种版本，满足不同场景需求，选择最适合您的版本，让您的业务更加高效...',
    link: '/scheme/Price'
  }
]
const footerSections = [
  {
    title: '关于我们',
    type: 'text',
    content: '填鸭表单致力于为企业和个人提供专业的在线问卷调查解决方案',
    socialLinks: [
      { icon: 'fab fa-weixin' },
      { icon: 'fab fa-weibo' },
      { icon: 'fab fa-github' }
    ]
  },
  {
    title: '产品',
    items: ['问卷调查', '满意度评价', '360度评估', '考试测评']
  },
  {
    title: '支持',
    items: ['帮助中心', 'API 文档', '技术支持', '常见问题']
  },
  {
    title: '联系我们',
    items: [
      { icon: 'fas fa-phone-alt', text: '400-888-8888' },
      { icon: 'fas fa-envelope', text: 'support@tduckform.com' },
      { icon: 'fas fa-map-marker-alt', text: '北京市朝阳区望京 SOHO T1' }
    ]
  }
]
const footerLinks = ['隐私政策', '服务条款', '法律声明']
onMounted(() => {
  // 处理场景导航栏的显示和隐藏
  const scenarioNav = document.getElementById('scenario-nav')
  const businessSection = document.getElementById('business-scenarios')
  
  const handleScroll = () => {
    if (!scenarioNav || !businessSection) return
    
    const dataCollectionSection = document.getElementById('data-collection')
    const voteVerifySection = document.getElementById('vote-verify')
    
    if (!dataCollectionSection || !voteVerifySection) return
    
    const businessRect = businessSection.getBoundingClientRect()
    const voteVerifyRect = voteVerifySection.getBoundingClientRect()
    const windowHeight = window.innerHeight
    
    // 判断是否在业务场景区域且未滚动到投票核销区域
    const shouldShow = businessRect.top <= windowHeight && 
                      businessRect.bottom >= 0 && 
                      voteVerifyRect.top > windowHeight
    
    if (shouldShow) {
      scenarioNav.style.transform = 'translateY(0)'
      scenarioNav.style.opacity = '1'
    } else {
      scenarioNav.style.transform = 'translateY(100%)'
      scenarioNav.style.opacity = '0'
    }
  }

  window.addEventListener('scroll', handleScroll)

  // 添加平滑滚动效果
  document.querySelectorAll('.scenario-nav-link').forEach(link => {
    link.addEventListener('click', (e) => {
      e.preventDefault()
      const targetId = link.getAttribute('href')
      if (targetId) {
        const targetElement = document.querySelector(targetId)
        if (targetElement) {
          targetElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          })
        }
      }
    })
  })

  // 清理事件监听器
  return () => {
    window.removeEventListener('scroll', handleScroll)
  }
})
const typeTexts = [
  '简单易用 - 快速创建您的第一个表单',
  '高并发能力 - 百万级数据加密保护',
  '私有化部署 - 支持本地部署和私有云部署',
  '100% 源码 - 完整源代码，自由定制功能',
  '技术支持 - 7*24h技术支持',
  '快速迭代 - 快速响应用户反馈，持续优化产品'
]
const currentTypeText = ref('')

const sleep = (ms: number|undefined) => new Promise(resolve => setTimeout(resolve, ms))

const typeNextText = async () => {
  while (true) {
    for (const text of typeTexts) {
      // 清空文本
      currentTypeText.value = ''
      await sleep(200)

      // 逐字输入
      for (let i = 0; i <= text.length; i++) {
        currentTypeText.value = text.substring(0, i)
        await sleep(50)
      }

      // 等待一段时间后继续下一个
      await sleep(2000)
    }
  }
}


const clickConsult = () => {
  window.open('https://pro.tduckcloud.com/s/QOt4xFOP', '_blank')
}

const toVideo = () => {
  window.open('https://space.bilibili.com/409825300/video', '_blank')
}

const goToDemo = (version: string) => {
  if (version === 'tduck') {
    window.open(`https://demo.tduckapp.com`, '_blank')
  } else if (version === 'tduckx') {
    window.open(`https://x.tduckcloud.com`, '_blank')
  } else if (version === 'tduckpro') {
    window.open(`https://pro.tduckcloud.com`, '_blank')
  }
}

onMounted(() => {
  typeNextText()
  
  // 初始化 AOS
  const elements = document.querySelectorAll('[data-aos]')
  setTimeout(() => {
    elements.forEach((el) => {
      el.classList.add('aos-animate')
    })
  }, 100)

  // 初始化 Swiper
  if (swiperRef.value) {
    new Swiper(swiperRef.value, {
      modules: [Pagination, Autoplay],
      slidesPerView: 1,
      pagination: {
        clickable: true
      },
      autoplay: {
        delay: 6000,
        disableOnInteraction: false
      },
      loop: true
    })
  }

  if (process.client) {
    // 创建Intersection Observer来监测图片元素
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-in')
          // 一旦动画完成，停止观察该元素
          observer.unobserve(entry.target)
        }
      })
    }, {
      threshold: 0.2// 当元素30%可见时触发动画
    })

    // 获取所有图片并开始观察
    const images = document.querySelectorAll('.function img')
    images.forEach((img: Element, index: number) => {
      // 添加延迟，使图片逐个显示
      setTimeout(() => {
        observer.observe(img)
      }, index * 100) // 每个图片延迟200ms
    })
  }
})
const modalStore = useModalStore()

// 导出给其他组件使用
defineExpose({
  modalStore
})
</script>
<style scoped>


/* 图片动画效果 */
.function img:hover {
  transform: scale(1.05);
}
.scenario-nav-link {
  transition: all 0.3s ease;
  position: relative;
}
.scenario-nav-link:hover {
  transform: translateY(-1px);
  text-shadow: 0 0 15px rgba(255,255,255,0.8);
  background: rgba(255,255,255,0.1);
  border-radius: 9999px;
}
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}
.animate-typing {
  display: block;
  overflow: hidden;
  width: auto;
  white-space: nowrap;
}
[data-aos="fade-up"] {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}
[data-aos="fade-up"].aos-animate {
  opacity: 1;
  transform: translateY(0);
}
.nav-link {
  position: relative;
}
.nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: rgb(139 92 246);
  transition: width 0.3s;
}
.nav-link:hover::after {
  width: 100%;
  background-color: rgb(139 92 246);
}
.group:hover .dropdown-menu {
  display: block;
  animation: fadeIn 0.2s ease-out;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-50% - 24px));
  }
}
.animate-scroll {
  animation: scroll 20s linear infinite;
}
.dropdown {
  position: relative;
}
.dropdown-menu {
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  transform: translateY(10px);
}
.group:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  display: block;
  transform: translateY(0);
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.dropdown-menu {
  z-index: 50;
}
.typing-container {
  display: inline-block;
  min-width: 1px;
}
.typing-text {
  display: inline-block;
  border-right: 2px solid #4C4ED3;
  white-space: nowrap;
  animation: blink 0.75s step-end infinite;
}
@keyframes blink {
  from, to { border-color: transparent }
  50% { border-color: #4C4ED3 }
}
.fan-container {
  perspective: 1000px;
}

.fan-item {
  opacity: 0;
  transform: rotateY(90deg) translateX(100px);
  animation: fanIn 0.8s ease-out forwards;
}

.fan-item:nth-child(1) { animation-delay: 0s; }
.fan-item:nth-child(2) { animation-delay: 0.2s; }
.fan-item:nth-child(3) { animation-delay: 0.4s; }

@keyframes fanIn {
  from {
    opacity: 0;
    transform: rotateY(90deg) translateX(100px);
  }
  to {
    opacity: 1;
    transform: rotateY(0) translateX(0);
  }
}

.fan-container:hover .fan-item:nth-child(1) { transform: translateX(-20px) scale(1.05); }
.fan-container:hover .fan-item:nth-child(3) { transform: translateX(20px) scale(1.05); }

@keyframes float {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-float {
  animation: float 0.8s ease-out forwards;
}

@keyframes float-1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes float-2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes float-3 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-12px);
  }
  100% {
    transform: translateY(0);
  }
}

.animate-float-1 {
  animation: float-1 3s ease-in-out infinite;
}

.animate-float-2 {
  animation: float-2 4s ease-in-out infinite;
  animation-delay: 0.5s;
}

.animate-float-3 {
  animation: float-3 3.5s ease-in-out infinite;
  animation-delay: 1s;
}

.scenario-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  background-color: rgba(237, 233, 254, 0.8);
  transition: all 0.3s ease;
}

.scenario-card:hover {
  box-shadow: 0 4px 20px rgba(239, 239, 239, 0.1);
}

/* 流程审批 */

.workflow .image-container {
  display: flex;
  width: 75%;
  transform: scale(0.9);
}

.workflow  .main-image-wrapper {
  width: 50%;
  position: relative;
  z-index: 10;
  transform: translateX(-70px) translateY(10px) scale(0.82);
}

.main-image {
  width: 100%;
  object-fit: cover;
  animation: float-1 6s ease-in-out infinite;
}

.workflow .sub-images-wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 80%;
  height: 100%;
  transform: translateX(-70px) translateY(74px);
}

.sub-image {
  flex: 1;
}

.sub-image-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: float-2 6s ease-in-out infinite;
}

.content-wrapper {
  width: 65%;
  margin-right: -32px;
  margin-left: 64px;
  padding-right: 32px;
}

.title-wrapper {
  margin-bottom: 16px;
}

.title {
  font-size: 36px;
  font-weight: 700;
  color: #111827;
}

.description {
  color: #4B5563;
  margin-bottom: 16px;
  line-height: 1.6;
}

.tags-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 16px;
}

.tag {
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: rgba(124, 58, 237, 0.1);
  color: #7C3AED;
}

.links-wrapper {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.link-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #7C3AED;
  text-decoration: none;
  transition: color 0.3s ease;
}

.link-item:hover {
  color: #6D28D9;
}

.icon {
  font-size: 12px;
  margin-right: 8px;
}

@keyframes float-1 {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

@keyframes float-2 {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-15px); }
}
/* 考试测评 */
.exam-card {
  background-color: rgba(195, 244, 221, 0.5);
}

.exam-card .image-container {
  display: flex;
  width: 75%;
  transform: scale(0.8); /* 与数据收集部分保持一致的整体缩放 */
}

.exam-card .main-image-wrapper {
  width: 45%;
  flex-shrink: 0;
  position: relative;
  z-index:0;
}

.exam-card .sub-images-wrapper {
  display: flex;
  flex-direction: column;
}

.exam-card .sub-images-wrapper .sub-image:first-child {
  display: flex;
  flex-direction: column;
  width: 100%;
  transform: translateX(20px) translateY(100px);
}

.exam-card .sub-images-wrapper .sub-image:last-child {
  width: 60%;
  transform: translateX(-100px) translateY(-20px);
}

.exam-card .title {
  font-size: 36px;
  font-weight: 700;
  color: #111827;
  line-height: 1.2;
}

.exam-card .description {
  color: #4B5563;
  line-height: 1.6;
  margin: 16px 0;
}

.exam-tag {
  background-color: rgba(16, 185, 129, 0.1);
  color: #059669;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}

.exam-link {
  color: #059669;
  font-size: 14px;
  display: block;
  margin-top: 8px;
}

.exam-link:hover {
  color: #047857;
}

/* 订单收款 */
.order-card {
  background-color: rgba(255, 249, 195, 0.5);
}

.order-card .image-container {  
  display: flex;
  width: 120%;
  transform: scale(0.8); /* 与数据收集部分保持一致的整体缩放 */
}

.order-card .main-image-wrapper {
  width: 120%;
  position: relative;
} 

.order-card .sub-images-wrapper {
  display: flex;
  gap: 20px;
  flex-direction: column;
  transform: translateX(20px) translateY(0px);
}

.order-card .sub-images-wrapper .sub-image:first-child {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.order-card .sub-images-wrapper .sub-image:last-child {
  width: 100%;
}

.order-card .title {
  font-size: 36px;
  font-weight: 700;
  color: #111827;
  line-height: 1.2;
}

.order-tag {
  background-color: rgba(255, 237, 174, 0.1);
  color: #f5740b;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}

.order-link {
  color: #f5740b;
  font-size: 14px;
  display: block;
  margin-top: 8px;
}

.order-link:hover {
  color: #f5510b;
}

/* 活动预约 */
.event-card {
  background-color: rgba(231, 230, 255, 0.5);
}

.event-card .image-container {
  display: flex;
  width: 120%;
  transform: scale(0.8); /* 与数据收集部分保持一致的整体缩放 */
}

.event-card .main-image-wrapper {
  position: relative;
} 

.event-card .sub-images-wrapper {
  display: flex;
  gap: 20px;
  flex-direction: column;
  transform: translateX(20px) translateY(0px);
} 

.event-card .sub-image:first-child {
  width: 100%;
}


.event-card .title {
  font-size: 36px;
  font-weight: 700;
  color: #111827;
  line-height: 1.2;
} 

.event-tag {
  background-color: rgba(255, 237, 174, 0.1);
  color: #5c74ff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}

.event-link {
  color: #7e91f9;
  font-size: 14px;
  display: block;
  margin-top: 8px;
}

.event-link:hover {
  color: #5c74ff; 
} 

/* 投票核销 */
.vote-card {
  background-color: rgba(252, 235, 255, 0.5);
}

.vote-card .image-container {
  display: flex;
  width: 110%;
  transform: scale(0.8); /* 与数据收集部分保持一致的整体缩放 */
} 

.vote-card .main-image-wrapper {
  position: relative;
  z-index: 10;
} 

.vote-card .sub-images-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  transform: translateX(20px) translateY(0px);
} 

.vote-card .sub-image:first-child { 
  width: 100%;
}

.vote-card .sub-image:last-child {
  width: 100%; 
}

.vote-card .title {
  font-size: 36px;
  font-weight: 700;
  color: #111827;
  line-height: 1.2;
}

.vote-tag {
  background-color: rgba(255, 237, 174, 0.1);
  color: #bb24fb; 
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}

.vote-link {
  color: #d37cf8;
  font-size: 14px;
  display: block;
  margin-top: 8px;
} 

.vote-link:hover {
  color: #bb24fb; 
} 

/* 模板打印 */
.template-card {
  background-color: rgba(213, 255, 244, 0.5);
} 

.template-card .image-container {
  display: flex;
  width: 100%;
  transform: scale(0.8);
} 
.template-sub-image1 {
  width: 50%;
  position: absolute;
  z-index: 10; /* 确保在最顶层 */
}

.template-sub-image2 {
  width: 50%;
  position: absolute;
  z-index: 20; /* 确保在最顶层 */
}


.template-card .sub-image:first-child {
  width: 100%;
  transform: translateX(20px) translateY(100px);
}

.template-card .sub-image:last-child {
  width: 100%;
  transform: translateX(-20px) translateY(-100px);

} 

.template-card .title {
  font-size: 36px;
  font-weight: 700;
  color: #111827;
  line-height: 1.2; 
}

.template-tag {
  background-color: rgba(255, 237, 174, 0.1);
  color: #5c74ff;
  padding: 4px 8px; 
  border-radius: 4px;
  font-size: 12px;
}

.template-link {
  color: #7e91f9; 
  font-size: 14px;
  display: block;
  margin-top: 8px;
} 

.template-link:hover { 
  color: #5c74ff; 
} 

#template-print .sub-image {
  width: 75%;
  position: absolute;
  top: 40%;
  z-index: 20;
}

.template-card .sub-image img {
  width: 80%; /* 确保图片填充容器 */
}
 .template-card .sub-image:first-child {
  left: 10%;
}

.sub-image:last-child {
  width: 100%;  
  right: -20%;
}

/* 添加移动端响应式样式 */
@media (max-width: 768px) {

  /* 隐藏状态卡片 */
  .state {
    display: none;
  }

  /* 隐藏场景卡片 */
  .scene {
    display: none;
  }
  /* 首屏区域适配 */
  .grid-cols-12 {
    grid-template-columns: 1fr !important;
  }
  
  .col-span-6 {
    height: 60%;
    grid-column: span 12 / span 12 !important;
    padding-right: 0 !important;
    text-align: center !important; /* 文字居中 */
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important; /* 内容居中对齐 */
  }

  /* 标题居中 */
  h1.text-6xl {
    font-size: 2.5rem !important;
    line-height: 1.2 !important;
    text-align: center !important;
  }

  /* 描述文字居中 */
  .text-xl {
    font-size: 1.125rem !important;
    text-align: center !important;
  }

  /* 调整打字机效果容器居中 */
  .space-y-6.mb-12 {
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
  }

  /* 调整打字机效果文字居中 */
  .space-y-6.mb-12 .flex.items-center {
    justify-content: center !important;
  }

  /* 调整按钮布局居中 */
  .space-x-6 {
    flex-direction: column;
    gap: 1rem;
    align-items: center !important; /* 按钮居中对齐 */
    width: 100% !important;
  }

  /* 按钮宽度调整 */
  .space-x-6 button {
    width: 80% !important; /* 设置按钮宽度 */
    max-width: 300px !important; /* 最大宽度限制 */
  }

  /* 值得信赖的企业级方案居中 */
  .col-span-12.flex.justify-center {
    margin-top: 2rem !important;
  }

  .flex.items-center.space-x-8 {
    flex-direction: column !important;
    gap: 1rem !important;
    text-align: center !important;
    width: 100% !important;
  }

  /* 移除分隔线 */
  .h-4.w-px.bg-gray-400 {
    display: none !important;
  }

  /* 调整企业级方案内容布局 */
  .flex.items-center.space-x-8 .flex.items-center {
    justify-content: center !important;
    width: 100% !important;
  }

  /* 调整企业级方案背景 */
  .flex.items-center.space-x-8.px-8.py-4 {
    background: transparent !important;
    border: none !important;
    padding: 0 !important;
  }

  /* 调整按钮容器布局 */
  .flex.items-center.space-x-6 {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    gap: 1rem !important;
    width: 100% !important;
    margin: 0 auto !important;
  }

  /* 调整按钮样式 */
  .flex.items-center.space-x-6 button {
    width: 80% !important;
    max-width: 300px !important;
    margin: 0 !important; /* 清除原有的间距 */
  }

  /* 确保按钮内容居中 */
  .flex.items-center.space-x-6 button i {
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  /* 在移动端隐藏企业级方案部分 */
  .col-span-12.flex.justify-center.mt-24 {
    display: none !important;
  }
}

/* 针对较小屏幕的额外调整 */
@media (max-width: 480px) {
  .grid-cols-4 {
    grid-template-columns: 1fr !important;
  }

  .grid-cols-6 {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .space-x-8 {
    flex-direction: column;
    gap: 1rem;
  }

  h1.text-6xl {
    font-size: 2rem !important;
  }

  .text-xl {
    font-size: 1rem !important;
  }

  .px-8 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .flex.items-center.space-x-6 button {
    width: 90% !important;
  }
}

/* 添加移动端样式 */
@media (max-width: 768px) {
  .demo-options {
    max-height: 80vh;
    overflow-y: auto;
  }

  .demo-option {
    min-height: 300px;
  }

  .demo-option img {
    max-height: 150px;
    object-fit: contain;
  }

  /* 优化滚动条样式 */
  .demo-options::-webkit-scrollbar {
    width: 4px;
  }

  .demo-options::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .demo-options::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  .demo-options::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

/* 针对更小屏幕的优化 */
@media (max-width: 480px) {
  .demo-option {
    min-height: 280px;
  }

  .demo-option h3 {
    font-size: 1.25rem;
  }

  .demo-option p {
    font-size: 0.875rem;
  }

  .demo-option button {
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
  }
}
</style>
